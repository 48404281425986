const aboutAPI = [
    {
        id: 1,
        logo: "fas fa-duotone fa-microscope",
        title: "Research",
        info: "Using modern approaches in machine learning, artificial intelligence, and real-time social media analysis, DaTAlab conducts research that leverages advanced technology to make complex problems manageable.",
    },
    {
        id: 2,
        logo: "fas fa-duotone fa-server",
        title: "Collect",
        info: "DaTALab houses one of the largest Twitter data repositories in North America which has been used to explore topics such as public health, diabetes, addictions/recovery and impacts of political messaging.",
    },
    {
        id: 3,
        logo: "fas fa-duotone fa-chart-line",
        title: "Analyze",
        info: "Our team applies modern reporting and visualization software tools to help in communicating meaningful results and conclusions to our stakeholders and funders.",
    },
]

export default aboutAPI;