import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/Footer';
import BackToTop from '../components/BackToTop/BackToTop';
import Accordion from 'react-bootstrap/Accordion';
import { db } from '../contexts/AuthContext';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import dateFormat from 'dateformat';

const Publications = () => {
    const [publications, setPublications] = useState([]);

    useEffect(() => {
        const publicationQuery = query(collection(db, "publications"), orderBy("publicationDate", "desc"));

        const fetchPublications = onSnapshot(publicationQuery, (publicationSnapshot) => {
            const data = [];
            publicationSnapshot.forEach((doc) => {
                data.push({ ...doc.data(), key: doc.id })
            });

            setPublications(data);
        });

        return () => {
            fetchPublications();
        };
    }, [])
    return (
        <>
            <Navbar />
            <section className='container recent-pubs' id='publications'>
                <div className='row'>
                    <h1 className='heading'>📜All Publications</h1>
                    <div className='col-12'>
                        {publications.length > 0 ? (publications.map((publication) =>
                            <Accordion>
                                <Accordion.Item className='pub-item'>
                                    <Accordion.Header>
                                        <div className='container-fluid pub-header'>
                                            <h4 className='pub-title'>{publication.publicationTitle}</h4>
                                            <p className='pub-authors'>{publication.authors}</p>
                                            <p className='pub-venue'>{publication.journal}; {dateFormat(publication.publicationDate, "mmm yyyy")}</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='container-fluid pub-body'>
                                            <p className='pub-abstract'>
                                                <b>Abstract: </b>
                                                {publication.abstract}
                                            </p>
                                            <a href={publication.manuscriptLink} className='btn view-pub-btn' target='_blank'>Full Article</a>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )) : " "}
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    )
}

export default Publications