import React, { useState } from 'react';
import fundingAgenciesAPI from '../../API/fundingAgenciesAPI';
import Marquee from 'react-fast-marquee';

const FundingAgencies = () => {

  const [fundingAgenciesData, setFundingAgenciesData] = useState(fundingAgenciesAPI);

  return (
    <>
      <div className='container-fluid funding-agencies' id='funding'>
        <h1 className='heading'>Funding Agencies</h1>
        <div className='fundingContainer'>
          <div className='fundingScroll'>
            <Marquee
              gradient={false}
              speed={80}
              pauseOnHover={true}
              pauseOnClick={true}
              delay={0}
              play={true}
              direction="left"
            >
              {/* All images must be 480px X 174px */}
              {
                fundingAgenciesData.map((currentElement) => {
                  const { name, image } = currentElement;
                  return (<>
                    <div className='fundingAgencyBox' >
                      <img src={image} alt={image} />
                      <h3 className='agencyName'>{name} </h3>
                    </div>
                  </>)
                })
              }
            </Marquee>
          </div>
        </div>
      </div >
    </>
  )
}

export default FundingAgencies