import React from 'react'
import Error404 from '../components/Error/Error404';

const Error = () => {
    return (
        <>
            < Error404 />
        </>
    )
}

export default Error