import React from 'react'
import Navbar from '../Navbar/navbar';
import Hero from '../Hero/Hero';
import About from '../About/About';
import Highlights from '../Highlights/Highlights';
import Publications from '../Publications/Publications';
import People from '../People/People';
import FundingAgencies from '../FundingAgencies/FundingAgencies';
import ContactUs from '../ContactUs/ContactUs';
import Footer from '../Footer/Footer';
import BackToTop from '../BackToTop/BackToTop';

const Home = () => {
    return (
        <>
            <Navbar />
            <Hero />
            <About />
            <Highlights />
            <Publications />
            <People />
            <FundingAgencies />
            <ContactUs />
            <Footer />
            <BackToTop />
        </>
    )
}

export default Home;
