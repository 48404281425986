import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/Footer';
import BackToTop from '../components/BackToTop/BackToTop';
import ListGroup from 'react-bootstrap/ListGroup';
import { db } from '../contexts/AuthContext';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import dateFormat from 'dateformat';

const Highlights = () => {
    const [highlights, setHighlights] = useState([]);

    useEffect(() => {
        const highlightQuery = query(collection(db, "highlights"), orderBy("highlightDate", "desc"));
        const fetchHighlights = onSnapshot(highlightQuery, (highlightSnapshot) => {
            const data = [];
            highlightSnapshot.forEach((doc) => {
                data.push({ ...doc.data(), key: doc.id })
            });

            setHighlights(data);
        });

        return () => {
            fetchHighlights();
        };
    }, [])
    return (
        <>
            <Navbar />
            <section className='highlights container-fluid' id='highlights'>
                <h1 className='text-center heading'>✨ All Highlights</h1>
                <div className='row'>
                    <div className='col-10 container'>
                        {highlights.length > 0 ? (highlights.map((highlight) =>
                            <ListGroup as='ul' className='highlightsList' key={highlight.key}>
                                <ListGroup.Item as='li' className='d-flex justify-content-between align-items-start'>
                                    <div className='ms-4 me-auto listItem'>
                                        <div className='fw-bold'>{highlight.highlight}</div>
                                        <i>{dateFormat(highlight.highlightDate, "mmm dd, yyyy")}</i>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        )) : " "}
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    )
}

export default Highlights