import React, { useState } from 'react'
import contactUsImg from '../../assets/images/contact-us.svg';
import { Alert } from 'react-bootstrap'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../contexts/AuthContext';

const todayDate = new Date();
const todayLocalDate = (new Date(todayDate.getTime() - todayDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 10);

const ContactUs = () => {
    const [userData, setUserData] = useState({
        "fullName": "",
        "email": "",
        "message": "",
    });

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;

        setUserData({ ...userData, [name]: [value] });
    }

    const submitQuery = async (e) => {
        e.preventDefault();

        try {
            setError('');
            const { fullName, email, message } = userData;
            if (fullName && email && message) {
                await addDoc(collection(db, "queries"), {
                    "fullName": fullName[0],
                    "email": email[0],
                    "message": message[0],
                    "timeStamp": todayLocalDate
                });
                setSuccess("Query submitted! We'll get back to you soon.")
            }
            setUserData({
                "fullName": "",
                "email": "",
                "message": ""
            })
        } catch (err) {
            setError('Failed to submit the query, reason: "' + err.message + '"')
        }
    }

    return (
        <>
            <section className='contact-us' id='contact'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-10 mx-auto'>
                            <div className='row'>
                                <h1 className='heading'>Contact Us</h1>
                                <p className='para'>Any questions or remarks? Just write us a message!</p>
                                <div className='left col-12 col-lg-5'>
                                    <img src={contactUsImg} alt='contactUsImg' className='img-fluid' />
                                </div>
                                <div className='right col-12 col-lg-7'>
                                    <h5>{error && <Alert variant='danger'>{error}</Alert>}</h5>
                                    <h5>{success && <Alert variant='success'>{success}</Alert>}</h5>
                                    <form method="POST" onSubmit={submitQuery}>
                                        <input className='w-100 form-control' type='text' name='fullName' placeholder='Your full name' required value={userData.fullName} onChange={postUserData} />
                                        <br />
                                        <input className='w-100 form-control' type='email' name='email' placeholder='Your email' required value={userData.email} onChange={postUserData} />
                                        <br />
                                        <textarea className='w-100 form-control' name='message' placeholder='Your message' required value={userData.message} onChange={postUserData}></textarea>
                                        <br />
                                        <button type='submit' className='btn submit-contactForm'>Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs