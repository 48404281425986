import computeCanada from '../assets/images/funding-agencies/compute-canada.jpg'
import greenstone from '../assets/images/funding-agencies/greenstone.png'
import lakehead from '../assets/images/funding-agencies/lakehead.png'
import york from '../assets/images/funding-agencies/york.png'
import mitacs from '../assets/images/funding-agencies/mitacs.png'
import noama from '../assets/images/funding-agencies/noama.png'
import nserc from '../assets/images/funding-agencies/nserc.png'
import oncat from '../assets/images/funding-agencies/oncat.png'
import promoscience from '../assets/images/funding-agencies/promoscience.png'
import revive from '../assets/images/funding-agencies/revive.png'
import sshrc from '../assets/images/funding-agencies/sshrc.png'
import wondeur from '../assets/images/funding-agencies/wondeur.png'

const fundingAgenciesAPI = [
    {
        name: 'Natural Sciences and Engineering Research Council of Canada (NSERC CRSNG)',
        image: nserc,
    },
    {
        name: 'Compute Canada',
        image: computeCanada,
    },
    {
        name: 'York University',
        image: york,
    },
    {
        name: 'Greenstone',
        image: greenstone,
    },
    {
        name: 'Lakehead University',
        image: lakehead,
    },
    {
        name: 'Mitacs',
        image: mitacs,
    },
    {
        name: 'Northern Ontario Academic Medicine Association',
        image: noama,
    },
    {
        name: 'Ontario Council on Articulation and Transfer (ONCAT)',
        image: oncat,
    },
    {
        name: 'NSERC Promoscience',
        image: promoscience,
    },
    {
        name: 'Revive',
        image: revive,
    },
    {
        name: 'Social Sciences and Humanities Research Council (SSHRC CRSH)',
        image: sshrc,
    },
    {
        name: 'Wondeur',
        image: wondeur,
    }
]

export default fundingAgenciesAPI;