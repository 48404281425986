import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/Footer';
import BackToTop from '../components/BackToTop/BackToTop';
import linkedInLogo from '../assets/images/linkedin-logo.png';
import { db } from '../contexts/AuthContext';
import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';

const People = () => {
    const [directors, setDirectors] = useState([]);
    const [collaborators, setCollaborators] = useState([]);
    const [postDocFellows, setPostDocFellows] = useState([]);
    const [phdStudents, setPhdStudents] = useState([]);
    const [mastersStudents, setMastersStudents] = useState([]);
    const [alumni, setAlumni] = useState([]);

    useEffect(() => {

        const directorsQuery = query(collection(db, "members"), where("role", "==", "Director"));
        const fetchDirector = onSnapshot(directorsQuery, (directorsSnapshot) => {
            const data = [];
            directorsSnapshot.forEach((doc) => {
                data.push({ ...doc.data(), key: doc.id })
            });

            setDirectors(data);
        });

        const collaboratorsQuery = query(collection(db, "members"), where("role", "==", "Collaborator"));
        const fetchCollaborators = onSnapshot(collaboratorsQuery, (collaboratosSnapshot) => {
            const data = [];
            collaboratosSnapshot.forEach((doc) => {
                data.push({ ...doc.data(), key: doc.id })
            });

            setCollaborators(data);
        });

        const PostDocFellowsQuery = query(collection(db, "members"), where("role", "==", "PostDoc Research Fellow"));
        const fetchPostDocFellows = onSnapshot(PostDocFellowsQuery, (PostDocFellowSnapshot) => {
            const data = [];
            PostDocFellowSnapshot.forEach((doc) => {
                data.push({ ...doc.data(), key: doc.id })
            });

            setPostDocFellows(data);
        });

        const PhdStudentsQuery = query(collection(db, "members"), where("role", "==", "PhD Student"));
        const fetchPhDStudents = onSnapshot(PhdStudentsQuery, (PhdStudentsSnapshot) => {
            const data = [];
            PhdStudentsSnapshot.forEach((doc) => {
                data.push({ ...doc.data(), key: doc.id })
            });

            setPhdStudents(data);
        });

        const MastersStudentsQuery = query(collection(db, "members"), where("role", "==", "Masters' Student"));
        const fetchMastersStudents = onSnapshot(MastersStudentsQuery, (MastersStudentsSnapshot) => {
            const data = [];
            MastersStudentsSnapshot.forEach((doc) => {
                data.push({ ...doc.data(), key: doc.id })
            });

            setMastersStudents(data);
        });

        const AlumniQuery = query(collection(db, "members"), where("role", "==", "Alumni"));
        const fetchAlumni = onSnapshot(AlumniQuery, (AlumniSnapshot) => {
            const data = [];
            AlumniSnapshot.forEach((doc) => {
                data.push({ ...doc.data(), key: doc.id })
            });

            setAlumni(data);
        });

        return () => {
            fetchDirector();
            fetchCollaborators();
            fetchPostDocFellows();
            fetchPhDStudents();
            fetchMastersStudents();
            fetchAlumni();
        };
    }, [])
    return (
        <>
            <Navbar />
            <section className='container-fluid researchTeam'>
                <div className='row'>
                    <h1 className='heading'>
                        Our Research Team
                    </h1>
                    <h3 className='memberType'>Director</h3>
                    <div className='row'>
                        {directors.length > 0 ? (directors.map((director) =>
                            <div className='col-12 col-lg-4 text-center'>
                                <img src={director.profileImage} className='profileImage'></img>
                                <div className='memberDetails'>
                                    <h4 classname="memberName">{director.name}</h4>
                                    <h5>{director.organization}</h5>
                                    <a href={director.linkedInURL} target="_blank">
                                        <img src={linkedInLogo} className="linkedInLogo"></img>
                                    </a>
                                </div>
                            </div>
                        )) : ""}
                    </div>
                    <h3 className='memberType'>Collaborators</h3>
                    <div className='row'>
                        {collaborators.length > 0 ? (collaborators.map((collaborator) =>
                            <div className='col-12 col-lg-4 text-center'>
                                <img src={collaborator.profileImage} className='profileImage'></img>
                                <div className='memberDetails'>
                                    <h4 classname="memberName">{collaborator.name}</h4>
                                    <h5>{collaborator.organization}</h5>
                                    <a href={collaborator.linkedInURL} target="_blank">
                                        <img src={linkedInLogo} className="linkedInLogo"></img>
                                    </a>
                                </div>
                            </div>
                        )) : ""}
                    </div>
                    <h3 className='memberType'>PostDoc Research Fellows</h3>
                    <div className='row'>
                        {postDocFellows.length > 0 ? (postDocFellows.map((postDocFellow) =>
                            <div className='col-12 col-lg-4 text-center'>
                                <img src={postDocFellow.profileImage} className='profileImage'></img>
                                <div className='memberDetails'> 
                                    <h4 classname="memberName">{postDocFellow.name}</h4>
                                    <h5>{postDocFellow.organization}</h5>
                                    <a href={postDocFellow.linkedInURL} target="_blank">
                                        <img src={linkedInLogo} className="linkedInLogo"></img>
                                    </a>
                                </div>
                            </div>
                        )) : ""}
                    </div>
                    <h3 className='memberType'>PhD Students</h3>
                    <div className='row'>
                        {phdStudents.length > 0 ? (phdStudents.map((phdStudent) =>
                            <div className='col-12 col-lg-4 text-center'>
                                <img src={phdStudent.profileImage} className='profileImage'></img>
                                <div className='memberDetails'>
                                    <h4 classname="memberName">{phdStudent.name}</h4>
                                    <h5>{phdStudent.organization}</h5>
                                    <a href={phdStudent.linkedInURL} target="_blank">
                                        <img src={linkedInLogo} className="linkedInLogo"></img>
                                    </a>
                                </div>
                            </div>
                        )) : ""}
                    </div>
                    <h3 className='memberType'>Masters' Students</h3>
                    <div className='row'>
                        {mastersStudents.length > 0 ? (mastersStudents.map((mastersStudent) =>
                            <div className='col-12 col-lg-4 text-center'>
                                <img src={mastersStudent.profileImage} className='profileImage'></img>
                                <div className='memberDetails'>
                                    <h4 classname="memberName">{mastersStudent.name}</h4>
                                    <h5>{mastersStudent.organization}</h5>
                                    <a href={mastersStudent.linkedInURL} target="_blank">
                                        <img src={linkedInLogo} className="linkedInLogo"></img>
                                    </a>
                                </div>
                            </div>
                        )) : ""}
                    </div>
                    <h3 className='memberType'>Alumni</h3>
                    <div className='row'>
                        {alumni.length > 0 ? (alumni.map((alumunus) =>
                            <div className='col-12 col-lg-4 text-center'>
                                <img src={alumunus.profileImage} className='profileImage'></img>
                                <div className='memberDetails'>
                                    <h4 classname="memberName">{alumunus.name}</h4>
                                    <h5>{alumunus.organization}</h5>
                                    <a href={alumunus.linkedInURL} target="_blank">
                                        <img src={linkedInLogo} className="linkedInLogo"></img>
                                    </a>
                                </div>
                            </div>
                        )) : ""}
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    )
}

export default People