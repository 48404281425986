import React from 'react'
import vjPic from '../../assets/images/vijay.jpg';
import { NavLink } from 'react-router-dom';

const People = () => {
    return (
        <>
            <section className='people' id='people'>
                <div className='container'>
                    <h1 className='people-heading'>Lab Director</h1>
                    <div className='director'>
                        <h2 className='people-subheading'>Dr. Vijay Mago</h2>
                        <div className='text-center row d-flex-r'>
                            <div className='col-12 col-lg-6 container-fluid director-para'>
                                Dr. Mago is an Associate Professor and Chair in School of Health Policy and Management at York University. Dr. Mago has a history of collaboration with researchers in other Canadian and international institutions (including India, the United States, Greece, Europe, and the United Kingdom). His research partnerships and collaborations also include industry, government, and non-profit organizations. He has attracted over $3.5 Million in external funding from a wide number of sources, including tri-council granting agencies (both NSERC and SSHRC), government, the public sector, and non-profit organizations. He has also published his research findings in a variety of high-quality international journals and conferences, and he has an extensive supervisory record.
                                <br />
                                &emsp;In addition to managing the DaTALab, Dr. Mago is an associate editor for two journals (IEEE Access, BMC Medical Informatics and Decision Making) and a member of the programme committee for numerous international conferences.

                                <NavLink className='btn btn-team' to='/researchTeam'>Know more about our research team </NavLink>
                            </div>
                            <div className='col-12 col-lg-6 container-fluid'>
                                <img className='director-img' src={vjPic} alt='Lab Director- Vijay Mago' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default People