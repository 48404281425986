import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar/navbar';
import Footer from '../components/Footer/Footer';
import BackToTop from '../components/BackToTop/BackToTop';
import Accordion from 'react-bootstrap/Accordion';
import { db } from '../contexts/AuthContext';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import dateFormat from 'dateformat';

const JoinUs = () => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const jobQuery = query(collection(db, "jobs"), orderBy("applicationDeadline", "asc"));

        const fetchJobs = onSnapshot(jobQuery, (jobSnapshot) => {
            const data = [];
            jobSnapshot.forEach((doc) => {
                data.push({ ...doc.data(), key: doc.id })
            });

            setJobs(data);
        });

        return () => {
            fetchJobs();
        };
    }, [])
    return (
        <>
            <Navbar />
            <section className='container join-us' id='jobs'>
                <div className='row'>
                    <h1 className='heading'>💼 All Jobs</h1>
                    <div className='col-12'>
                        {jobs.length > 0 ? (jobs.map((job) =>
                            <Accordion>
                                <Accordion.Item className='job-item'>
                                    <Accordion.Header>
                                        <div className='container-fluid job-header'>
                                            <h4 className='job-title'>{job.jobTitle}</h4>
                                            <p className='job-subheading'>
                                                Application Deadline: {dateFormat(job.applicationDeadline, "mmmm dd, yyyy")} ||
                                                Start Date: {dateFormat(job.startDate, "mmm dd, yyyy")} ||
                                                Location: {job.jobLocation}
                                            </p>
                                            {/* <p className='pub-venue'>{job.journal}; {dateFormat(job.publicationDate, "mmm yyyy")}</p> */}
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='container-fluid job-body'>
                                            <p className='job-desc'>
                                                <b>Job Responsibilities: </b><br />
                                                {job.jobResponsibilities} <br />
                                                <b>Qualifications: </b><br />
                                                {job.qualifications}<br />
                                                <b>Job Duration: </b>
                                                {job.jobDuration} <br />
                                                <b>Application Process: </b>
                                                {job.applicationProcess}<br />
                                                <b>Hourly pay range: </b>
                                                CAD {job.payRange} per hr
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )) : "We don't have any openings at the moment. Please check back soon."}
                    </div>
                </div>
            </section>
            <Footer />
            <BackToTop />
        </>
    )
}

export default JoinUs