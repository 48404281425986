import React from 'react'
import { Route, Routes } from 'react-router';
import Home from './components/Home/Home';
import Highlights from './pages/Highlights';
import Publications from './pages/Publications';
import People from './pages/People';
import JoinUs from './pages/JoinUs';
import Error from './pages/Error';

const App = () => {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Home />}></Route>
        <Route exact path='/allHighlights' element={<Highlights />}></Route>
        <Route exact path='/allPublications' element={<Publications />}></Route>
        <Route exact path='/researchTeam' element={<People />}></Route>
        <Route exact path='/join' element={<JoinUs />}></Route>
        <Route exact={true} path='*' element={<Error />}></Route>
      </Routes>
    </>
  )
}

export default App;
