import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';

const Footer = () => {
    return (
        <>
            <footer className='footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-lg-10 mx-auto'>
                            <div className='row'>
                                <div className='col-6 col-lg-6'>
                                    <ul>
                                        <li>
                                            <NavLink to='/#hero'
                                                smooth={true}
                                                spy='true'
                                                duration={2000}>
                                                Home
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/#about'
                                                smooth={true}
                                                spy='true'
                                                duration={2000}>
                                                About Us
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/#highlights'
                                                smooth={true}
                                                spy='true'
                                                duration={2000}>
                                                Highlights
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/#publications'
                                                smooth={true}
                                                spy='true'
                                                duration={2000}>
                                                Publications
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-6 col-lg-6'>
                                    <ul>
                                        <li>
                                            <NavLink to='/researchTeam'>Our Research Team</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/#funding'
                                                smooth={true}
                                                spy='true'
                                                duration={2000}>
                                                Funding Agencies
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/join'>Join Us</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/#contact'
                                                smooth={true}
                                                spy='true'
                                                duration={2000}>
                                                Contact Us
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='mt-3'>
                        <p className='para text-center w-100'>
                            Copyright &copy; {new Date().getFullYear()} DaTALab. All Rights Reserved | Developed by <a href='https://manmeetkaurbaxi.com' target='_blank'>Manmeet Kaur Baxi</a>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer