import React, { useState } from 'react'
import { NavHashLink as NavLink } from 'react-router-hash-link';

const Navbar = () => {
    const [showNav, setShowNav] = useState(false);

    return (
        <>
            <section className='navbar-bg'>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <NavLink className="navbar-brand" to='/'>DaTALab</NavLink>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShowNav(!showNav)}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${showNav ? "show" : ""}`}>
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link active" aria-current="page" to="/">Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link"
                                        to="/#about"
                                        smooth={true}
                                        spy='true'
                                        duration={2000}>
                                        About us
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link"
                                        to="/#highlights"
                                        smooth={true}
                                        spy='true'
                                        duration={2000}>
                                        Highlights
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link"
                                        to="/#publications"
                                        smooth={true}
                                        spy='true'
                                        duration={2000}>
                                        Publications
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link"
                                        to="/#people"
                                        smooth={true}
                                        spy='true'
                                        duration={2000}>
                                        People
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link"
                                        to="/#funding"
                                        smooth={true}
                                        spy='true'
                                        duration={2000}>
                                        Funding Agencies
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link"
                                        to="/join">
                                        Join us
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link"
                                        to="/#contact"
                                        smooth={true}
                                        spy='true'
                                        duration={2000}>
                                        Contact us
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>
        </>
    )
}

export default Navbar;