const researchAreasAPI = [
    {
        logo: "fas fa-duotone fa-people-group",
        title: "Social Network"
    },
    {
        logo: "fas fa-duotone fa-database",
        title: "Data Mining"
    },
    {
        logo: "fas fa-duotone fa-chart-line",
        title: "Text Analytics"
    },
    {
        logo: "fas fa-duotone fa-laptop-code",
        title: "Modelling & Simulation"
    },
]

export default researchAreasAPI;