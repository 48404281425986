import React from 'react'
import error404 from '../../assets/images/404 error.png';
import { NavLink } from 'react-router-dom';

const Error404 = () => {
  return (
    <>
      <div className='container' id='notFound'>
        <div className='notFound'>
          <img src={error404} alt='404-error' />
          <p>The page you are looking for might have been removed, or is temporarily unavailable.</p>
          <NavLink to='/'>Go back to homepage</NavLink>
        </div>
      </div>
    </>
  )
}

export default Error404