import React, { useState } from 'react'
import aboutAPI from '../../API/aboutAPI';
import researchAreasAPI from '../../API/researchAreasAPI';

const About = () => {
  const [aboutData, setAboutData] = useState(aboutAPI);
  const [researchAreasData, setResearchAreasData] = useState(researchAreasAPI);

  return (
    <>
      <section id='about'>
        <div className='about-container container'>
          <h1 className='main-heading text-center'>About Us</h1>
          <p className='about-para w-100'>DaTALab is a research laboratory in the School of Health Policy and Management at York University. Our team of students, faculty, and researchers is focused on conducting research that can be applied to real-world social and economic problems.</p>
          <div className='row'>
            {
              aboutData.map((currentElement) => {
                const { id, logo, title, info } = currentElement;
                return (<>
                  <div className='col-12 col-lg-4 text-center about-container-subdiv'>
                    <i class={`fontawesome-style ${logo}`}></i>
                    <h2 className="subheading">{title}</h2>
                    <p className='main-hero-para w-100'>{info}</p>
                  </div>
                </>)
              })
            }
          </div>

          <h2 className='main-subheading text-center fw-bold'>🔬 Our Research Areas</h2>
          <div className='researchAreas'>
            <div className='row'>
              {
                researchAreasData.map((currentElement) => {
                  const { logo, title } = currentElement;
                  return (
                    <>
                      <div className='col-12 col-lg-6 col-xxl-6 text-center researchAreas-subdiv'>
                        <i class={`researchAreasIcon ${logo}`}></i>
                        <h2 className='researchAreaHeading'>{title}</h2>
                      </div>
                    </>
                  )
                })
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About