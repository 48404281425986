import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import ListGroup from 'react-bootstrap/ListGroup';
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';
import { db } from '../../contexts/AuthContext';
import { collection, query, orderBy, limit, onSnapshot, getDocs, where } from 'firebase/firestore';
import dateFormat from 'dateformat';

const Highlights = () => {
    const [highlights, setHighlights] = useState([]);
    const [publications, setPublicationSize] = useState([0]);
    const [thesis, setThesisDefendedSize] = useState([0]);

    useEffect(() => {
        const highlightQuery = query(collection(db, "highlights"), orderBy("highlightDate", "desc"), limit(5));
        const fetchRecentHighlights = onSnapshot(highlightQuery, (highlightSnapshot) => {
            const data = [];
            highlightSnapshot.forEach((doc) => {
                data.push({ ...doc.data(), key: doc.id })
            });

            setHighlights(data);
        });

        const getPublicationSize = async (publications) => {
            const pSize = await getDocs(query(collection(db, "publications")));
            setPublicationSize(publications => pSize.docs.length);
        };

        const getThesisDefendedSize = async (thesis) => {
            const mSize = await getDocs(query(collection(db, "members"), where("role", "==", "Alumni")));
            setThesisDefendedSize(thesis => mSize.docs.length);
        };

        return () => {
            fetchRecentHighlights();
            getPublicationSize();
            getThesisDefendedSize();
        };
    }, [publications, thesis])

    return (
        <>
            <section className='highlights container-fluid' id='highlights'>
                <h1 className='text-center heading'>✨ Recent Highlights</h1>
                <div className='row'>
                    <div className='col-10 container'>
                        {highlights.length > 0 ? (highlights.map((highlight) =>
                            <ListGroup as='ul' className='highlightsList' key={highlight.key}>
                                <ListGroup.Item as='li' className='d-flex justify-content-between align-items-start'>
                                    <div className='ms-4 me-auto listItem'>
                                        <div className='fw-bold'>{highlight.highlight}</div>
                                        <i>{dateFormat(highlight.highlightDate, "mmm dd, yyyy")}</i> &nbsp;
                                        {highlight.highlightLink !== '' ? (<a href={highlight.highlightLink} target='_blank'>[Link]</a>) : ''}
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        )) : " "}
                        <NavLink className='btn button-style' to='/allHighlights'>View all highlights ✨</NavLink>
                    </div>
                </div>
                <div className='container'>
                    <div className='row'>
                        {/* TODO: Delat CountUp load until the div loads */}
                        {/* TODO: Get the total number of publications from the database */}
                        <div className='col-12 col-lg-3 highlight-card'>
                            <h2 className='fw-bold text-center card-heading'>Publications</h2>
                            <div className='card-counter'> <CountUp end={96} className='card-counter' delayedCall redraw={true}/> +</div> 
                            <div className='text-justify card-content'>We publish in interdisciplinary journals and Computer Science conferences.</div>
                        </div>
                        {/* Get total number of Grants */}
                        <div className='col-12 col-lg-3 highlight-card'>
                            <h2 className='fw-bold text-center card-heading'>Grants</h2>
                            <div className='card-counter'><CountUp end={20} className='card-counter' delayedCall redraw={true}/> +</div> 
                            <div className='text-justify card-content'>Our research is supported by internal and extramural funding.</div>
                        </div>
                        {/* TODO: Get total number of Lakehead alumni from the database*/}
                        <div className='col-12 col-lg-3 highlight-card'>
                            <h2 className='fw-bold text-center card-heading'>Thesis</h2>
                            <div className='card-counter'> <CountUp end={40} className='card-counter' delayedCall redraw={true}/> +</div> 
                            <div className='text-justify card-content'>Students have successfully defended their thesis, and we applaud them.</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Highlights
