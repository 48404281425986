import React from 'react'
import hero from '../../assets/images/website-covers/hero.gif';
import { NavHashLink as NavLink } from 'react-router-hash-link';;

const Hero = () => {
    return (
        <>
            <header>
                <section className='container main-hero-container' id='hero'>
                    <div className='row'>
                        <div className='col-12 col-lg-6 header-left-side d-flex justify-content-center flex-column align-items-start'>
                            <h1 className='display-2'>Data and Text Analytics Lab<br />(DaTALab)</h1>
                            <p className='main-hero-para'>
                                We devise Data Science approaches and apply them to solve complicated challenges. While we are computer scientists, our work is generally multidisciplinary, and our lab embraces partnerships.
                            </p>
                            <div className='input-group mt-3'>
                                <NavLink className='btn button-style'
                                    to="/#highlights"
                                    smooth={true}
                                    spy='true'
                                    duration={2000}>
                                    ✨ Highlights
                                </NavLink>
                                <NavLink className='btn button-style'
                                    to="/#publications"
                                    smooth={true}
                                    spy='true'
                                    duration={2000}>
                                    📜 Publications</NavLink>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 header-right-side d-flex justify-content-center align-items-center main-hero-image'>
                            <img src={hero} alt='heroimg' className='img-fluid' />
                        </div>
                    </div>
                </section>
            </header>
        </>
    )
}

export default Hero