import React, { useState, useContext } from 'react';
import { IoIosArrowDropupCircle } from 'react-icons/io';

const BackToTop = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <div className='backToTop' style={{ display: visible ? 'inline' : 'none' }}>
                <button onClick={scrollToTop} aria-label='Back to top'>
                    <IoIosArrowDropupCircle className='icon' />
                </button>
            </div>
        </>
    )
}

export default BackToTop